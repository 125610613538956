@use "@/styles/global/Vars.scss";

.buttonBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;  
  max-width: 600px;

  @media (min-width: 600px) {
    margin-left: 50%;
    transform: translateX(-50%);
  }

  background-color: white;
}

.RatingContainer {
  height: calc(100dvh - 136px);
}

.Comment {
  width: calc(100% - 8px);
  min-height: 30vh;
  box-shadow: Vars.$main-shadow;
}

.EncounterButton:hover {
  border: 1px solid #3562ff;
  span {
    color: #3562ff;
  }
  background-color: rgba(53, 98, 255, 0.1);
  path {
    stroke: rgb(53 98 255);
  }
  transition: all 0.1s ease;
}
.EncounterButtonChecked {
  opacity: 1;
  border: 1px solid #3562ff;
  span {
    color: #3562ff;
  }
  background-color: rgba(53, 98, 255, 0.1);
  path {
    stroke: rgb(53 98 255);
  }
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
}

.CheckboxRoot {
  background-color: transparent;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-grey-3);
  cursor: pointer;
  flex: none;
}

.CheckboxRoot:hover {
  background-color: var(--color-grey-6);
}

.CheckboxIndicator {
  color: var(--color-grey-6);
}

.CheckboxRoot[data-state="checked"] {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.CheckboxFlex {
  display: flex;
  width: 100%;
  cursor: pointer;
  gap: 15px;
}

.reason__textarea {
  padding: 3%;
  background: Vars.$white-color;
  border: 0.5px solid #959595;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  &:focus {
  border: 2px solid var(--color-primary);
  }
  &::placeholder {
      font-size: 1.1rem;
  }
  font-size: 1rem;
}