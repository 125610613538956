.container{
    margin: 20px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ol{
        li{
            margin-bottom: 10px;
        }
    }
}