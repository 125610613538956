.checkout-body-subtitle {
    width: 100%;
    padding-top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.checkout-body-currency {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5;
    color: var(--color-primary);
    padding: 0;
    margin-right: 8px;
}

.checkout-body-price {
    font-weight: 900;
    font-size: 3rem;
    line-height: 1;
    color: var(--color-primary);
}

.checkout-body-type {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;

    text-align: center;
    margin-right: 8px;
}

.checkout__discardPrice {
    text-decoration: none;
    display: flex;
    margin-left: 2px;
    justify-content: center;
    align-items: center;

    p {
        font-style: normal;
        font-weight: bold;
        font-size: 6.93128px;
        line-height: 9px;
        color: #9a9a9a;
    }
}

.checkout__cancelText {
    position: relative;
    width: 36px;
    height: 32px;
}

.checkout__oldPrice {
    position: absolute;
    top: 0;
    left: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 23.2323px;
    line-height: 32px;
    color: #9a9a9a;
}