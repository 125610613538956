.container {
    width: 100%;
    height: 49px;
    margin: 16px 0;

    /* Grey/6 */
    background: #F2F2F2;

    /* Grey/ 5 */
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px 18px 10px 16px;
}

.view_container {
    padding: 24px 16px 0px 16px;
}

.inactive {
    opacity: 0.7;
}

.ButtonItem {
    all: unset;
    display: block;
    background-color: white;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    border: 2px solid var(--color-primary);
    cursor: pointer;
    flex-shrink: 0;

    &[data-inactive="true"] {
        background: var(--color-grey-5);
        border: 2px solid var(--color-grey-4);
    }
}

.ButtonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-grey-6);
    border: 1px solid var(--color-grey-5);
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    padding-right: 14px;
}

.Indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;

    &:after {
        content: '';
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: var(--color-primary);
    }
}

.Label {
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding: 16.5px 18px 16.5px 16px;
}

.Bottom {
    width: calc(100% - 32px);
    position: absolute;
    bottom: calc(0px + env(keyboard-inset-height, 0px));
    margin-bottom: 12px;
    text-align: center;
}

.Alert {
    display: flex;
    flex-direction: column;
    background-color: #FFFBDB;
    border: 1px solid #FFEF5F;
    padding: 16px;
    text-align: left;
    margin-bottom: 12px;
    border-radius: 8px;

    p {
        padding: 2px;
    }


}

.suscriptionText{
    border-radius: 100px;
    color: var(--color-grey-2);
}