@use "@/styles/global/Vars.scss";

.Link {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color:Vars.$color-grey-6;
    padding: 12px;
    border-radius: 8px;
    &:focus, &:focus-within, &:focus-visible {
        outline: 2px solid Vars.$color-primary--hover;
    }
    gap: 4px;
}

.isPrincipal {
    background: rgba(Vars.$color-primary, 0.3);
}
