.skeletonContainer {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0;
}

.cardContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex: 1;
    /* Hacer que el enlace ocupe todo el espacio disponible en el contenedor */
    padding: 12px 18px 12px 12px;
    align-content: center;
    align-items: center;

}