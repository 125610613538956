.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
}
.paragraph{
    text-align: center;
}
.buttonsContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 16px;
}