.EncounterContainer{
    width: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    ul{
        width: 100%;
    }
    h1{
        font-weight: bold;
        font-size: 19px;
    }
    h3{
        color: #0A91E4;
        font-size: 14px;
        font-weight: 300;
    }
    .encounterTitle{
        font-size: 19px;
        font-weight: 300;
    }
    .encountersList{
        width: 100%;
        li {
                box-shadow: 0px 4px 8px 0px rgba(38, 36, 48, 0.1);
                border-radius: 4px;
                height: 60px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                margin-bottom:12px;
                padding: 0;
                &:first-child {
                    margin-top: 12px;
                }
                align-content: center;
                align-items: center;

        }
    }
}

.cardContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: start!important;
    flex: 1;
    /* Hacer que el enlace ocupe todo el espacio disponible en el contenedor */
    padding: 12px 18px 12px 12px;
    align-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: rgba(10, 145, 228, 0.1);
    color: #0A91E4;
    .infoContainer{
        width: 70%;
        h3{
            font-weight: bold;
            font-size: 15px;
        }
        p{
            font-weight: 400;
            font-size: 10px;
            width: 50%;
        }
    }
}

.encounterCard{
    border-radius: 20px!important;
}

.medicalRecordList {
    width: 100%;
    li {
            border-radius: 4px;
            height: 72px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            margin-bottom:12px;
            padding: 0;
            &:first-child {
                margin-top: 12px;
            }
            align-content: center;
            align-items: center;
    }
}

.bottom {
    width: calc(100% - 32px);
    position: fixed;
    bottom: 20px;
    text-align: center;
    background-color: white;
}
