.container{
    margin: 16px;
}
.derivationContainer{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #7692B5;
    padding: 8px 0;
    &:hover{
        cursor: pointer;
    }
}
.infoContainer{
    margin: 0 16px;
    display: flex;
    flex-direction: column;
}