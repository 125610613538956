@use '@/styles/global/Vars.scss';

.stepperContainer {
  width: 130%;
  position: absolute;
  left: -15%;
}
.stepper {
  width: 100%;
  padding: 10px 0 0 0 !important;
  .step {
    width: 16px !important;
    height: 16px !important;
    background-color: white !important;
    border: 1px solid var(--color-primary) !important;
    .dot {
      width: 6px;
      height: 6px;
      background-color: var(--color-primary);
      border-radius: 50%;
    }
  }
  .active {
    width: 16px !important;
    height: 16px !important;
    background-color: var(--color-primary) !important;
  }
}