.recoveryContainer {
    padding: 32px;
    height: 75%;

    @media screen and (max-height: 677px) {
        height: 65%;
    }
}

.account {
    width: calc(100% - 12px);
    height: 64px;
    background-color: var(--color-grey-6);

    &:hover {
        background-color: var(--color-grey-5);
    }

    padding: 12px;
    border-radius: 8px;
    margin: 4px;

}


.input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
}

.RecoveryForm {
    height: 80%;
}