.MedicalRecordList {
  width: 100%;

  li {
    box-shadow: 0px 4px 8px 0px rgba(38, 36, 48, 0.1);
    border-radius: 4px;
    height: 72px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 12px;
    padding: 0;
    &:first-child {
      margin-top: 12px;
    }
    align-content: center;
    align-items: center;
  }
}

.doctorFlex {
  display: flex;
  flex-direction: column;
}

.Bottom {
  width: calc(100% - 32px);
  position: absolute;
  bottom: calc(0px + env(keyboard-inset-height, 0px));
  margin-bottom: 20px;
  text-align: center;
}

.AttentionQR {
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: flex-start;
}

.cardContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex: 1;
  /* Hacer que el enlace ocupe todo el espacio disponible en el contenedor */
  padding: 12px 18px 12px 12px;
  align-content: center;
  align-items: center;
}

.pagePrint {
  padding: 24px;
  @page {
    size: auto;
    margin: 60mm;
  }
}

.EncounterContainer {
  padding: 32px;
  .encounterTitle {
    font-weight: bold;
  }
}
