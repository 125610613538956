@use "@/styles/global/Vars";

.reason__content {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 10px;
    margin-top: 16px;
}

.reason__textarea {
    padding: 3%;
    background: Vars.$white-color;
    border: 0.5px solid #959595;
    box-sizing: border-box;
    border-radius: 10px;
    height: 20vh;
    width: 100%;

    &:focus {
        border: 2px solid var(--color-primary);
    }

    &::placeholder {
        font-size: 1.1rem;
    }

    font-size: 1rem;
}

.error {
    border: 2px solid#D72727;
}

.reason__buttonBottom {
    position: fixed;
    bottom: 16px;
    padding: 0;
    max-width: 560px;
    width: 90%;
    box-sizing: content-box;
}