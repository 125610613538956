.FullLoaderContainer {
    margin: auto;
    height: 100vh; /* Fallback */
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.Logo {
    display: flex;
    width: 190;
    height: 78;
}

.Loader {
    position: relative;
    top: -20px;
}

.left {
    text-align: left;
}

.center {
    text-align: center;
}

.ContentContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}