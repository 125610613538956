@use "@/styles/global/Vars.scss";

.corporate_wrapper {
    padding: 24px 16px;
    height: 90vh;
    position: relative;
    box-sizing: border-box;
}

.NoResults {
    background: white;
    height: 54px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
        padding: 12px;
    }
}

.comboboxList {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
}

.coverages {
    margin: 16px;
}

.CargarManualmente{
    background-color: Vars.$color-grey-6;
    z-index: 1;
}

.noCoverage {
    display: flex;
    flex-direction: column;
    text-align: start;
}