.confirmContainer{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .userIconContainer{
        width: 72px;
        height: 72px;
        background: #CFDAFF;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto auto;
      }
    .create{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .bullets{
        display: flex;
        justify-content: center;
    }
}

.appointmentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 54px);
    text-align:center;
}

.paymentContainer{
    padding: 16px;
    position: fixed;
    bottom: 16px;
}

.confirmAppointment__CTA {
    width: calc(100% - 32px);
    position: absolute;
    bottom: calc(16px + env(keyboard-inset-height, 0px));
    left: 16px;
    margin: auto auto;
    margin-bottom: 0px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}