.Header {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.VerticalSpacer {
    width: 1px;
    height: 51px;
    background-color: var(--color-grey-4);
    border-radius: 2px;
    margin: 0 12px;
    
}

.SeparatorGrey3 {
    margin: 0;
    height: 1px;
    background-color: var(--color-grey-4);
    width: 100%;
    border-radius: 4px;
    border: 0;
}

.center {
    text-align: center;
}

.Container {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.DoctorInfo {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    top: -32px;
}

.FlexboxContainer {
    justify-content: center;
    text-align: center;
}