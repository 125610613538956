@use '@/styles/index.scss';

.fullHeight {
    height: calc(100dvh - 40px);
}

.Logo {
    margin-bottom: 32px;
}

.Bottom {
    width: calc(100% - 24px);
    position: absolute;
    bottom: calc(0px + env(keyboard-inset-height, 0px));
    margin-bottom: 12px;
    text-align: center;
}

.progressBar {
    width:100%;
    height: 3px;
    background: #3562FF;
    position: relative;
    top: -12px;
}