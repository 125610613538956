.container{
    display: flex;
    flex-direction: column;
    margin: 24px 16px;
    justify-content: space-between;
}
.infoContainer{
    display: flex;
    flex-direction: column;
}
.buttonContainer{
    position: absolute;
    width: 100%;
    bottom: 24px;
    right: 0px;
    padding: 16px;
}
.borderBottom{
    width: 100%;
    height: 1px;
    margin: 8px 0;
    background-color: grey;
}