.iconsContainer{
    height: 200px;
    width: 100%;
    background-color: #fff8d8;
    border-radius: 10px 10px 50% 50% ;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    
}
.dialogContent{
    padding: 0px!important;
    .icon{
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.modalFooter{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 50vh;
    gap: 20px;
    margin: 20px;
    text-align: center;
}