@media screen and (min-width: 400px) {
	.App {
		max-width: 600px;
		height: 100vh; /* Fallback */
		height: 100dvh;
		margin: auto;
		overflow: auto;
		.occ__fullLoader {
			left: 0;
		}
	}
}

.App {
  height: 100vh; /* Fallback */
	height: 100dvh;
	position: relative;
	overflow-x: hidden;
	background: no-repeat white;
}

